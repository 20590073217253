import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { Container } from "@mui/material";
import axios from "axios";

function Gallery() {
  const [imageData, setImageData] = useState([]);

  // Fetch images from the API
  useEffect(() => {
    window.scrollTo(500, 0); // Scrolls to top of the page on component load
    const fetchImages = async () => {
      try {
        const response = await axios.get(
          "https://techhubindia.co.in/admin/public/api/gallery-details"
        );
        setImageData(response.data); // Assumes API response is an array of image objects
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div style={{ overflow: "hidden", marginTop: "20px" }}>
      <Container sx={{ paddingY: 4 }}>
        <div
          style={{
            textAlign: "left",
            marginTop: "2%",
            marginBottom: "2%",
            fontSize: "25px",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          Gallery
        </div>
        <Grid container spacing={3} justifyContent="center">
          {imageData.map((image) => (
            <Grid item xs={12} sm={6} md={4} key={image.id}>
              <Card
                sx={{
                  boxShadow: 6,
                  borderRadius: 2,
                  overflow: "hidden",
                  transition: "transform 0.3s",
                  "&:hover": { transform: "scale(1.05)" },
                }}
              >
                <CardMedia
                  component="img"
                  image={image.gallery_pic} // Assuming API provides 'gallery_pic' for image URL
                  alt={image.title}
                  sx={{
                    width: "100%",
                    height: "400px",
                    objectFit: "cover",
                    transition: "transform 0.3s",
                  }}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default Gallery;
