import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS
import login1 from './login1.jpg';

function Login() {
  const [formData, setFormData] = useState({
    email_id: "",
    pass: "",
  });

  const navigate = useNavigate();

  // Handler to update form data on input change
  const handler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Function to handle form submission
  const loginData = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const response = await axios.post(
        "https://techhubindia.co.in/admin/public/api/login", // Adjust the endpoint for login
        {
          email: formData.email_id,
          password: formData.pass,
        }
      );

      if (response.status === 200) {
        // Assuming a successful login returns a token or user data
        toast.success("Login successful!"); // Show success toast
        // Store the token and email in local storage
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("email_id", response.data.email_id); // Store email ID
        // Redirect to a different page after successful login
        navigate("/"); // Change to your desired route
      } else {
        toast.error("Login failed. Please try again."); // Show error toast
      }
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : "An error occurred."
      ); // Show error toast for exceptions
    }
  };

  return (
    <>
      
      <div
        className="container-fluid bg-dark"
        style={{
          backgroundImage:`url(${login1})`,
          objectFit: "contain",
        }}
      >
    
        <div className="container pb-4" style={{ paddingTop: "96px" }}>
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6 shadow bg-light rounded px-5 py-3">
              <h3 align={"left"}>Continue with Sign In</h3>
              <form method="post" onSubmit={loginData} align={"left"}>
                <div className="mb-3" style={{ marginTop: "2%" }}>
                  <label htmlFor="formGroupExampleInput" className="form-label">
                    Email ID
                  </label>
                  <input
                    type="text"
                    name="email_id"
                    onChange={handler}
                    className="form-control border-2"
                    id="formGroupExampleInput"
                    placeholder=""
                  />
                </div>

                <div className="mb-3" style={{ marginTop: "2%" }}>
                  <label
                    htmlFor="formGroupExampleInput2"
                    className="form-label"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="pass"
                    onChange={handler}
                    className="form-control border-2"
                    id="formGroupExampleInput2"
                    placeholder=""
                  />
                </div>
                <div className="mb-3" style={{ marginTop: "2%" }}>
                  <div align="center">
                    <input
                      type="submit"
                      value="Sign In"
                      className="my-btn w-100"
                    />
                    <div className="mt-3">
                      Don't have an Account?{" "}
                      <Link to={"/signup"} className="text-decoration-none">
                        Sign Up
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer /> {/* Add ToastContainer to your JSX */}
    </>
  );
}

export default Login;
