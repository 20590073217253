import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import {
  removeFromCart,
  clearCart,
  updateCartItemQuantity,
} from "../store/slices/cardSlice"; // Adjust the path to your cartSlice file
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Cart() {
  const cartItems = useSelector((state) => state.cart.cartItems);
  const cartTotalQty = useSelector((state) => state.cart.cartTotalQty); // Fetch total quantity from Redux
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleRemoveFromCart = (id, selectedSize) => {
    dispatch(removeFromCart({ id, selectedSize }));
    toast.error("Item removed from cart");
  };

  const handleClearCart = () => {
    dispatch(clearCart());
    toast.error("Cart cleared");
  };

  const handleQuantityChange = (id, selectedSize, newQuantity) => {
    dispatch(updateCartItemQuantity({ id, selectedSize, newQuantity }));
  };

  const calculateItemTotal = (item) => {
    return item.cartQuantity * item.product_price_after;
  };

  const totalAmount = cartItems.reduce(
    (total, item) => total + calculateItemTotal(item),
    0
  );

  const handleProceedToCheckout = () => {
    const checkoutInfo = {
      cartItems,
      totalAmount,
      cartTotalQty,
    };

    // Use navigate to go to the checkout page and pass the state
    navigate("/checkout", { state: { checkoutInfo } });
  };

  return (
    <div
      className="container-fluid mt-5"
      style={{ fontFamily: "-moz-initial" }}
    >
      <div className="row">
        <div className="col-md-9">
          <h2 className="" style={{ textAlign: "left" }}>
            My Cart
          </h2>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Cards</th>
                  <th scope="col">Title</th>
                  <th scope="col">Name</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Price</th>
                  <th scope="col">Total</th>
                  <th scope="col">Remove</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item) => (
                  <tr key={`${item.id}-${item.selectedSize}`}>
                    <td data-label="Product">
                      <div className="cart-item">
                        <img
                          className="cart-item-image"
                          src={item.card_pic}
                          style={{
                            width: "100px",
                            height: "60px",
                            objectFit: "contain",
                          }}
                          alt={item.card_name}
                        />
                        {/* <div className="cart-item-details">
                          <a
                            href={`product-detail/${item.id}`}
                            className="text-decoration-none"
                          >
                            <span style={{ fontSize: "12px" }}>
                              {item.card_title}
                            </span>
                          </a>
                        </div> */}
                      </div>
                    </td>
                    <td data-label="Size">{item.card_title}</td>
                    <td data-label="Color">{item.card_name}</td>
                    <td data-label="Quantity">
                      <div className="d-flex align-items-center">
                        <button
                          className="btn btn-sm btn-outline-secondary me-2"
                          type="button"
                          onClick={() =>
                            handleQuantityChange(
                              item.id,
                              item.selectedSize,
                              item.cartQuantity - 1
                            )
                          }
                          disabled={item.cartQuantity <= 1}
                        >
                          -
                        </button>
                        <span className="input-group-text me-2">
                          {item.cartQuantity}
                        </span>
                        <button
                          className="btn btn-sm btn-outline-secondary"
                          type="button"
                          onClick={() =>
                            handleQuantityChange(
                              item.id,
                              item.selectedSize,
                              item.cartQuantity + 1
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                    </td>

                    <td data-label="Price">₹ {item.product_price_after}</td>
                    <td data-label="Total">₹ {calculateItemTotal(item)}</td>
                    <td data-label="Remove" className="text-center">
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() =>
                          handleRemoveFromCart(item.id, item.selectedSize)
                        }
                      >
                        <i className="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mb-2" style={{ textAlign: "right" }}>
            <button className="btn btn-primary w-25" onClick={handleClearCart}>
              Clear Cart
            </button>
          </div>
        </div>
        <div className="col-md-3">
          <h2 className="" style={{ textAlign: "left" }}>
            Cart Summary
          </h2>
          <div className="card mb-4">
            <div className="card-body">
              <ul className="list-group list-group-flush">
                {cartItems.map((item) => (
                  <li
                    key={`${item.id}-${item.selectedSize}`}
                    className="list-group-item"
                  >
                    {/* {item.product_name} - {item.selectedSize} -{" "} */}
                    {item.cartQuantity} x ₹ {item.product_price_after} = ₹{" "}
                    {calculateItemTotal(item)}
                  </li>
                ))}
              </ul>
              {/* <p>Total Items: {cartTotalQty}</p> */}
              <p>Total Amount: ₹ {totalAmount}</p>
              <div className="mb-2" style={{ textAlign: "right" }}>
                <button
                  className="btn btn-primary w-100"
                  onClick={handleProceedToCheckout}
                >
                  Proceed to Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Cart;
