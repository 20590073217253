import React, { useRef } from "react";
import { FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
import emailjs from "emailjs-com"; // Import EmailJS
import "bootstrap/dist/css/bootstrap.css";
import "./Contact.css";

function Contact() {
  window.scrollTo(500, 0);
  const form = useRef();

  // Function to handle form submission
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_3ez57dc", // Replace with your EmailJS Service ID
        "template_tralnkk", // Replace with your EmailJS Template ID
        form.current,
        "pJOAZNW0x5hAjbc09" // Replace with your EmailJS User ID
      )
      .then(
        (result) => {
          alert("Message sent successfully!");
        },
        (error) => {
          alert("Failed to send message, please try again.");
        }
      );

    e.target.reset(); // Reset the form after submission
  };

  return (
    <div
      className="contact-container container py-5"
      style={{ fontFamily: "-moz-initial" }}
    >
      <h2 className="text-center mb-4">Contact Us</h2>
      <div className="row">
        {/* Contact Information */}
        <div className="col-md-6 mb-4">
          <h4>Get in Touch</h4>
          <p>
            We would love to hear from you! Please fill out the form or use the
            information below to get in touch with us.
          </p>
          <ul className="list-unstyled">
            <li className="d-flex align-items-center mb-2">
              <FaMapMarkerAlt className="me-2" />
              <span>Mahalaxmipura, Baran Road, Kota, Rajasthan</span>
            </li>
            <li className="d-flex align-items-center mb-2">
              <FaPhoneAlt className="me-2" />
              <span>+91 7014015639</span>
            </li>
            <li className="d-flex align-items-center mb-2">
              <FaEnvelope className="me-2" />
              <span><a href="#">businesstechhubjjr@gmail.com</a></span>
            </li>
          </ul>
        </div>

        {/* Contact Form */}
        <div className="col-md-6">
          <form ref={form} onSubmit={sendEmail} className="contact-form">
            <div className="mb-3">
              <label htmlFor="name" className="form-label float-start">
                Your Name
              </label>
              <input
                type="text"
                name="user_name" // EmailJS uses this name attribute
                className="form-control"
                id="name"
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label float-start">
                Email Address
              </label>
              <input
                type="email"
                name="user_email" // EmailJS uses this name attribute
                className="form-control"
                id="email"
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label float-start">
                Message
              </label>
              <textarea
                name="message" // EmailJS uses this name attribute
                className="form-control"
                id="message"
                rows="4"
                placeholder="Write your message"
                required
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary w-100">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
