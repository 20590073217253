import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import login1 from "./login1.jpg";

function SignUp() {
  // State variables to store form data
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
    password: "",
  });

  // Handler to update state when input values change
  const handler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Function to handle form submission and send data to the backend
  const signFormData = async (e) => {
    e.preventDefault(); // Prevent page refresh
    try {
      const response = await axios.post(
        "https://techhubindia.co.in/admin/public/api/signup",
        formData
      );

      if (response.status === 201) {
        toast.success("Signup successful! Please log in.");
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          mobile_no: "",
          password: "",
        });
      } else {
        toast.error("Signup failed. Please try again.");
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        // Validation error handling
        const errorMessages = error.response.data;
        for (const key in errorMessages) {
          toast.error(`${key}: ${errorMessages[key]}`);
        }
      } else {
        // General error handling
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <div
        className="container-fluid bg-dark"
        style={{
          backgroundImage:`url(${login1})`,
          objectFit: "contain",
        }}
      >
        <div className="container pb-4" style={{ paddingTop: "96px" }}>
          <div className="row">
            <div className="col-md-6 p-0">{/* Placeholder for image */}</div>
            <div className="col-md-6 shadow bg-light rounded px-5 py-3">
              <h3 align="left">Continue with Sign Up</h3>
              <form onSubmit={signFormData}>
                <div className="mb-3">
                  <label
                    htmlFor="first_name"
                    className="form-label float-start"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handler}
                    className="form-control"
                    id="first_name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="last_name" className="form-label float-start">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handler}
                    className="form-control"
                    id="last_name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label float-start">
                    Email id
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handler}
                    className="form-control"
                    id="email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="mobile_no" className="form-label float-start">
                    Mobile Number
                  </label>
                  <input
                    type="tel"
                    name="mobile_no"
                    value={formData.mobile_no}
                    onChange={handler}
                    className="form-control"
                    id="mobile_no"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label float-start">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handler}
                    className="form-control"
                    id="password"
                    required
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="submit"
                    value="Sign UP"
                    className="my-btn w-100"
                  />
                </div>
                <div className="mt-3">
                  Already have an Account?{" "}
                  <Link to={"/login"} className="text-decoration-none">
                    Log In
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
