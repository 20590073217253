import { createSlice, createSelector } from "@reduxjs/toolkit";

// Initial state loaded from localStorage
const initialState = {
  cartItems: JSON.parse(localStorage.getItem("cartItems")) || [],
  cartTotalQty: JSON.parse(localStorage.getItem("cartTotalQty")) || 0,
};

// Selector to select cart items from state
const selectCartItems = (state) => state.cart.cartItems;

// Selector to calculate the total number of distinct items in the cart
export const selectTotalCartItems = createSelector(
  selectCartItems,
  (items) => items.length
);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // Action to add a visiting card to the cart
    addToCart: (state, action) => {
      const {
        id,
        c_name,
        card_name,
        card_title,
        card_desc,
        card_pic,
        product_price_after,
      } = action.payload;

      const existingItemIndex = state.cartItems.findIndex(
        (item) => item.id === id
      );

      if (existingItemIndex !== -1) {
        // Item already exists, increase quantity
        state.cartItems[existingItemIndex].cartQuantity += 1;
      } else {
        // Item does not exist, add it
        const newItem = {
          id,
          c_name,
          card_name,
          card_title,
          card_desc,
          card_pic,
          cartQuantity: 1,
          product_price_after, // Store the price
        };
        state.cartItems.push(newItem);
      }

      state.cartTotalQty += 1; // Increase total quantity
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    // Action to remove a visiting card from the cart
    removeFromCart: (state, action) => {
      const { id } = action.payload;
      const existingItemIndex = state.cartItems.findIndex(
        (item) => item.id === id
      );

      if (existingItemIndex !== -1) {
        if (state.cartItems[existingItemIndex].cartQuantity > 1) {
          state.cartItems[existingItemIndex].cartQuantity -= 1;
        } else {
          state.cartItems.splice(existingItemIndex, 1);
        }
        state.cartTotalQty -= 1; // Decrease total quantity
      }
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    // Action to clear the cart
    clearCart: (state) => {
      state.cartItems = [];
      state.cartTotalQty = 0;
      localStorage.removeItem("cartItems");
    },
    // Action to update the quantity of a visiting card in the cart
    updateCartItemQuantity: (state, action) => {
      const { id, newQuantity } = action.payload;
      const existingItem = state.cartItems.find((item) => item.id === id);

      if (existingItem) {
        existingItem.cartQuantity = newQuantity;
        state.cartTotalQty = state.cartItems.reduce(
          (total, item) => total + item.cartQuantity,
          0
        );
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      }
    },
  },
});

export const { addToCart, removeFromCart, clearCart, updateCartItemQuantity } =
  cartSlice.actions;

export default cartSlice.reducer;
