import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { addToCart } from "../store/slices/cardSlice"; // Correct path to your cartSlice

function Details() {
  const isLoggedIn = !!localStorage.getItem("token");
  const email_id = localStorage.getItem("email");
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(true);

  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    navigate("/login");
    window.location.reload();
  };

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const { data } = await axios.get(
          `https://techhubindia.co.in/admin/public/api/card-details/${id}`
        );
        setProduct(data);
        fetchSimilarProducts(data.c_name);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };
    fetchProductDetails();
  }, [id]);

  const fetchSimilarProducts = async (c_name) => {
    try {
      const { data } = await axios.get(
        `https://techhubindia.co.in/admin/public/api/similar/${id}`
      );
      setSimilarProducts(data);
    } catch (error) {
      console.error("Error fetching similar products:", error);
    }
  };

  const toggleDescription = () => {
    setIsDescriptionOpen((prev) => !prev);
  };

  const handleAddToCart = () => {
    if (product) {
      const {
        id,
        c_name,
        card_name,
        card_title,
        card_desc,
        old_price,
        new_price,
        card_pic,
      } = product;
      dispatch(
        addToCart({
          id,
          c_name,
          card_name,
          card_title,
          card_desc,
          card_pic,
          cartQuantity: 1,
          product_price_after: new_price, // Ensure to set this price
        })
      );
    }
  };

  const goToNext = (id) => {
    window.scrollTo(500, 0);
    navigate(`/details/${id}`);
  };

  if (!product) return <div>Loading...</div>;
  const calculateDiscountPercentage = (before, after) => {
    return before && after && before !== 0
      ? Math.round(((before - after) / before) * 100)
      : 0;
  };

  const truncateWords = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };
  const wordLimit = 5;
  return (
    <div
      className="container-fluid mb-5 px-5"
      style={{ marginTop: "80px", fontFamily: "-moz-initial" }}
    >
      <div className="row">
        <div className="col-md-6">
          <div
            className="banner-slider-container bg-light"
            onClick={() => setShowModal(true)}
            style={{ cursor: "pointer" }}
          >
            <img
              src={product.card_pic}
              alt={product.card_name}
              style={{
                width: "100%",
                height: "400px",
                margin: "5px",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
        <div className="col-md-6 text-start">
          <h6 className="mt-3">
            <span className="fs-5 text-danger">{product.c_name}</span>
          </h6>
          <h3 className="fw-bold mt-3">{product.card_name}</h3>
          <div
            className="mt-3"
            dangerouslySetInnerHTML={{ __html: product.card_desc }}
          />
          <div className="mt-3 px-3">
            <span className="fs-2 fw-bold text-muted">
              {" "}
              <del style={{ color: "red" }}>₹{product.old_price}</del>
            </span>
            <span className="fs-2 fw-bold"> ₹{product.new_price} </span>
            <span className="fs-2 fw-bold text-success">
              {calculateDiscountPercentage(
                product.old_price,
                product.new_price
              )}
              % off
            </span>
            {!isLoggedIn ? (
              <Link className="my-btn1 mx-2 text-decoration-none" to="/login">
                <i
                  className="fa-solid fa-right-to-bracket mx-2"
                  style={{ fontSize: "1rem" }}
                ></i>
                Add to cart
              </Link>
            ) : (
              <button
                type="button"
                onClick={handleAddToCart}
                className="my-btn1 mx-4"
              >
                <i className="fa-solid fa-cart-plus"></i> Add to cart
              </button>
            )}
          </div>
          <div className="" align="center">
            {/* <button
              type="button"
              onClick={handleAddToCart}
              className="my-btn1 mx-4"
            >
              <i className="fa-solid fa-cart-plus"></i> Add to cart
            </button> */}
          </div>
        </div>
      </div>

      {/* Description Toggle Section */}
      <button
        className="custom-accordion-button collapsible fw-bold my-btn w-100 text-left mt-5"
        onClick={toggleDescription}
        style={{ marginBottom: "1rem", border: "1px solid #003C71" }}
      >
        Description
        <i
          className={`fa-solid fa-angle-${
            isDescriptionOpen ? "down" : "up"
          } float-end mt-1`}
        ></i>
      </button>

      {isDescriptionOpen && (
        <div className="row">
          <div className="col-md-12">
            <div
              className="mx-5 text-start"
              dangerouslySetInnerHTML={{ __html: product.card_desc }}
            />
          </div>
        </div>
      )}

      {/* Modal for Image Preview */}
      <Modal
        style={{ paddingTop: "5rem" }}
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-fullscreen"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{product.card_name} - Product Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center align-items-center">
          <img
            src={product.card_pic}
            alt={product.card_name}
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "80%",
              objectFit: "contain",
            }}
          />
        </Modal.Body>
      </Modal>

      {/* Similar Products Section */}
      <div className="mt-5">
        <h4 style={{ textAlign: "left", fontWeight: "bold" }}>
          Similar Products
        </h4>
        <div className="row">
          {similarProducts.map((similarProduct) => (
            <div
              className="col-md-3 col-sm-6 mt-2 mydes"
              key={similarProduct.id}
            >
              <div className="card h-100">
                <img
                  src={similarProduct.card_pic} // Use a fallback image if product_icon is undefined
                  alt={similarProduct.card_name}
                  className="card-img-top"
                  style={{ objectFit: "fill" }}
                />
                <div className="card-body" align={"left"}>
                  <h5 className="card-title fw-bold">
                    {similarProduct.card_title}
                  </h5>
                  <p className="card-text fw-bold">
                    {similarProduct.card_name}
                  </p>
                  <p className="card-text">
                    <del className="text-danger fw-bold fs-5">
                      ₹{similarProduct.old_price}
                    </del>{" "}
                    <strong className="fw-bold fs-4">
                      ₹{similarProduct.new_price}
                    </strong>
                  </p>
                  {/* <button className="btn btn-primary w-100">Buy Now</button> */}
                  <input
                    type="submit"
                    value="Buy Now"
                    className="w-100 m-0 p-0 p-2 my-btn fw-bold text-decoration-none"
                    onClick={() => goToNext(similarProduct.id)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Details;
