import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectTotalCartItems } from "../store/slices/cardSlice";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import Logo from "../../images/logo.jpg";
import "./header.css";

export const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const totalCartItems = useSelector(selectTotalCartItems);
  const isLoggedIn = !!localStorage.getItem("token");
  const navigate = useNavigate();

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    navigate("/login");
    window.location.reload();
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "left",
        backgroundColor: "#34a0bf",
        height: "100vh",
        paddingX: 2,
        // paddingTop: 2,
      }}
    >
      {/* Logo on the left */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // marginBottom: 2,
        }}
      >
        <img
          src={Logo}
          alt="logo"
          style={{
            width: "50%",
            borderRadius: "50rem",
          }}
        />

        {/* Cart button on the right */}
        {isLoggedIn && (
          <NavLink to="/cart" className="cart-icon" style={{ color: "white" }}>
            <ShoppingCartCheckoutIcon sx={{ color: "white", fontSize: 30 }} />
            <sup className="badge bg-danger">{totalCartItems}</sup>
          </NavLink>
        )}
      </Box>

      {/* Divider */}
      <Divider sx={{ borderColor: "white", mb: 2 }} />

      {/* Menu items */}
      <ul style={{ listStyle: "none", padding: 2 }}>
        <li>
          <NavLink to="/" className="nav-item">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/cards" className="nav-item">
            Cards
          </NavLink>
        </li>
        <li>
          <NavLink to="/service" className="nav-item">
            Services
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" className="nav-item">
            About
          </NavLink>
        </li>
        <li>
          <NavLink to="/gallery" className="nav-item">
            Gallery
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact" className="nav-item">
            Contact
          </NavLink>
        </li>
      </ul>

      {/* Login/Logout button at the end */}
      <Box sx={{ marginTop: 3 }}>
        {!isLoggedIn ? (
          <NavLink to="/login" className="nav-link" style={{ color: "white" }}>
            <Button variant="contained" startIcon={<LoginIcon />}>
              Login
            </Button>
          </NavLink>
        ) : (
          <Button
            onClick={handleLogout}
            variant="contained"
            sx={{ bgcolor: "red" }}
            startIcon={<LogoutIcon />}
          >
            Logout
          </Button>
        )}
      </Box>
    </Box>
  );

  return (
    <Box>
      <AppBar component="nav" sx={{ bgcolor: "rgb(34, 160, 191)" }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Logo on the left */}
          <Typography variant="h6" component="div">
            <img
              src={Logo}
              alt="logo"
              style={{
                objectFit: "cover",
                width: "102px",
                borderRadius: "5rem",
              }}
            />
          </Typography>

          {/* Centered navigation items - hidden on mobile */}
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexGrow: 1,
              justifyContent: "center",
            }}
          >
            <ul className="navigation-menu">
              <li>
                <NavLink to="/" className="nav-item">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/cards" className="nav-item">
                  Cards
                </NavLink>
              </li>
              <li>
                <NavLink to="/service" className="nav-item">
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" className="nav-item">
                  About
                </NavLink>
              </li>
              <li>
                <NavLink to="/gallery" className="nav-item">
                  Gallery
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact" className="nav-item">
                  Contact
                </NavLink>
              </li>
            </ul>
          </Box>

          {/* Right-aligned cart and login/logout buttons - hidden on mobile */}
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              alignItems: "center",
            }}
          >
            {isLoggedIn && (
              <NavLink
                to="/cart"
                className="cart-icon"
                style={{ marginRight: "16px" }}
              >
                <ShoppingCartCheckoutIcon
                  sx={{ color: "white", fontSize: 30 }}
                />
                <sup className="badge bg-danger">{totalCartItems}</sup>
              </NavLink>
            )}
            {!isLoggedIn ? (
              <NavLink
                to="/login"
                className="nav-link mx-2"
                style={{ marginRight: "16px" }}
              >
                <Button variant="contained" startIcon={<LoginIcon />}>
                  Login
                </Button>
              </NavLink>
            ) : (
              <Button
                onClick={handleLogout}
                variant="contained"
                sx={{ bgcolor: "red", marginRight: "16px" }}
                startIcon={<LogoutIcon />}
              >
                Logout
              </Button>
            )}
          </Box>

          {/* Mobile menu icon */}
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": { width: 260, backgroundColor: "#34a0bf" },
          "& .MuiPaper-root": { padding: "10px" },
        }}
      >
        {drawer}
      </Drawer>
      <Toolbar />
    </Box>
  );
};
