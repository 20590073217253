import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";

const API_BASE_URL =
  "https://techhubindia.co.in/admin/public/api/slide-details";

function Swiper() {
  const sliderRef = useRef(null);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_BASE_URL);
        const data = response.data;

        const fetchedSlides = data.map((item) => ({
          id: item.id,
          slide_pic: item.slide_pic,
        }));

        setSlides(fetchedSlides);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: true,
  };

  return (
    <div className="banner-slider-container mt-3">
      <Slider ref={sliderRef} {...settings} style={{ width: "100%" }}>
        {slides.map((slide, index) => (
          <div className="slide" key={index}>
            {slide.slide_pic ? (
              <img src={slide.slide_pic} alt="Slide" className="slide-image" />
            ) : (
              <div className="slide-placeholder">
                <p>No image available</p>
              </div>
            )}
          </div>
        ))}
      </Slider>

      {/* Custom CSS for responsiveness */}
      <style>
        {`
          .slide-image {
            width: 100%;
            height: 380px;
            object-fit: fill;
            object-position: center;
          }

          .slide-placeholder {
            width: 100%;
            height: 380px;
            background: #ddd;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          @media (max-width: 768px) {
            .slide-image,
            .slide-placeholder {
              height: 200px; /* Adjust height for smaller screens */
            }
          }

          @media (max-width: 480px) {
            .slide-image,
            .slide-placeholder {
              height: 150px; /* Further adjust height for extra small screens */
            }
          }
        `}
      </style>
    </div>
  );
}

export default Swiper;
