import React from 'react'
import './footer.css'
import { FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <footer>
            <div className="container-fluid"
                style={{ background: 'rgb(34, 160, 191)' }}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="footer_title">about us</div>
                                    <div className="my_list">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/about">About us</Link></li>
                                            <li><Link to="/cards">View All cards</Link></li>
                                            <li><Link to="/service">Services</Link></li>
                                            <li><Link to="/contact">Contact us</Link></li>

                                        </ul>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="footer_title">Services</div>
                                    <div className="my_list">
                                        <ul>
                                            <li><Link to="/cards">Visiting Card Design</Link></li>
                                            <li><Link to="/cards">Wedding Card Design</Link></li>
                                            <li><Link to="/cards">Brochure Design</Link></li>
                                            <li><Link to="/cards">Flyer Design</Link></li>
                                            <li><Link to="/cards">Business Card Design</Link></li>
                                            <li><Link to="/cards">Letterhead Design</Link></li>
                                            <li><Link to="/cards">Envelope Design</Link></li>


                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="footer_title">Contact us</div>
                                    <div className="my_list">
                                        <address style={{ color: 'white' }}>
                                           
                                            <ul>
                                            <p style={{ color: "white" }}>
                                                We would love to hear from you! Please fill out the form or use the
                                                information below to get in touch with us.
                                            </p>
                                                <li className="d-flex align-items-center mb-2">
                                                    <FaMapMarkerAlt className="me-2" />
                                                    <span>Mahalaxmipura, Baran Road, Kota, Rajasthan</span>
                                                </li>
                                                <li className="d-flex align-items-center mb-2">
                                                    <FaPhoneAlt className="me-2" />
                                                    <span>+91 7014015639</span>
                                                </li>
                                                <li className="d-flex align-items-center mb-2">
                                                    <FaEnvelope className="me-2" />
                                                    <span><a href="#">businesstechhubjjr@gmail.com</a></span>
                                                </li>
                                            </ul>
                                        </address>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div style={{ background: 'rgb(34, 160, 191)', height: "80px", color: 'white', textAlign: 'center', padding: '25px' }}>
                            © 2023 Tech Hub . All rights reserved. developed by coder basket software company .
                        </div>
                    </div>
                </div>


            </div>
        </footer>
    )
}
