import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./Cards.css"; // Import the CSS file

function Cards() {
  window.scrollTo(500, 0);
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Fetch card data from the API
  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await axios.get(
          "https://techhubindia.co.in/admin/public/api/card-details"
        );
        setCards(response.data);
      } catch (err) {
        setError("Failed to fetch cards. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchCards();
  }, []);

  // Render loading, error, or cards
  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-danger">{error}</div>;
  }

  const goToNext = (id) => {
    navigate(`/details/${id}`);
  };

  return (
    <div
      className="cards-container container"
      style={{ fontFamily: "-moz-initial" }}
    >
      <h2 className="text-left mb-4 fw-bold" align={"left"}>
        Cards
      </h2>
      <div className="row">
        {cards.map((card) => (
          <div className="col-md-3 col-sm-6 mb-4" key={card.id}>
            {/* <div className="card h-100">
              <img
                src={card.card_pic}
                alt={card.brand_name}
                className="card-img-top"
                style={{ objectFit: "fill" }}
              />
              <div className="card-body" align={"left"}>
                <h5 className="card-title fw-bold">{card.card_title}</h5>
                <p className="card-text fw-bold">{card.card_name}</p>
                <p className="card-text">
                  <del className="text-danger fw-bold fs-5">
                    ₹{card.old_price}
                  </del>{" "}
                  <strong className="fw-bold fs-4">₹{card.new_price}</strong>
                </p>
                <input
                  type="submit"
                  value="Buy Now"
                  className="w-100 m-0 p-0 p-2 my-btn fw-bold text-decoration-none"
                  onClick={() => goToNext(card.id)}
                />
              </div>
            </div> */}
           
                  <div className="service-card card h-100">
                  <img
                src={card.card_pic}
                alt={card.brand_name}
                className="card-img-top"
                style={{ objectFit: "fill",height:"150px" }}
              />
                   <div className="card-body" align={"left"}>
                <h5 className="card-title fw-bold">{card.card_title}</h5>
                <p className="card-text fw-bold">{card.card_name}</p>
                <p className="card-text">
                  <del className="text-danger fw-bold fs-5">
                    ₹{card.old_price}
                  </del>{" "}
                  <strong className="fw-bold fs-4">₹{card.new_price}</strong>
                </p>
                <input
                  type="submit"
                  value="Buy Now"
                  className="w-100 m-0 p-0 p-2 my-btn fw-bold text-decoration-none"
                  onClick={() => goToNext(card.id)}
                />
              </div>
                  </div>
                </div>
      
          
        ))}
      </div>
    </div>
  );
}

export default Cards;
