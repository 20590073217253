import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swiper from "../layout/Swiper";
import './index.css';
import {
  FaCode,
  FaMobileAlt,
  FaChartLine,
  FaShoppingCart,
} from "react-icons/fa";

function Index() {
  window.scrollTo(500, 0);
  const navigate = useNavigate();

  // State to hold fetched categories and products
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [activeCollapse, setActiveCollapse] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://techhubindia.co.in/admin/public/api/card-details"
        );
        const data = response.data;

        const fetchedProducts = data.map((item) => ({
          id: item.id,
          category_name: item.c_name,
          product_icon: [item.card_pic],
          brand_name: item.card_name,
          main_category_name: item.card_title,
          old_price: item.old_price,
          new_price: item.new_price,
        }));

        const uniqueCategories = Array.from(
          new Set(fetchedProducts.map((product) => product.category_name))
        ).map((name) => ({ category_name: name }));

        setProducts(fetchedProducts);
        setCategories(uniqueCategories);

        const initialCollapseState = uniqueCategories.reduce((acc, curr) => {
          acc[curr.category_name] = true;
          return acc;
        }, {});
        setActiveCollapse(initialCollapseState);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const toggleCollapse = (category_name) => {
    setActiveCollapse((prev) => ({
      ...prev,
      [category_name]: !prev[category_name],
    }));
  };

  const filteredProducts = (category_name) =>
    category_name
      ? products.filter((product) => product.category_name === category_name)
      : products;

  const goToNext = (id) => {
    navigate(`details/${id}`);
  };

  const truncateWords = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  const wordLimit = 5;

  return (
    <div style={{ fontFamily: "-moz-initial" }}>
    <br />
      <Swiper />
      {/* Desktop View */}
      <div className="container-fluid px-5 d-none d-md-block my-3">
        <h5 className="text-start fw-bold text-muted my-3">
          Explore what's trending
        </h5>
        <div className="row">
          <div className="col-md-3">
            <ul className="list-group" style={{ listStyleType: "none" }}>
              <li
                key="all"
                className="my-btn"
                style={{
                  background: "#003C71",
                  color: "white",
                  cursor: "pointer",
                  textAlign: "left",
                  fontFamily: "-moz-initial",
                }}
                onClick={() => setSelectedCategory(null)}
              >
                All
                <i className="fa-solid fa-caret-right float-end mt-1"></i>
              </li>
              {categories.map((item, index) => (
                <li
                  key={index}
                  className="my-btn mt-2"
                  onClick={() => setSelectedCategory(item.category_name)}
                  style={{
                    cursor: "pointer",
                    textAlign: "left",
                    fontFamily: "-moz-initial",
                    fontWeight: "bold",
                  }}
                >
                  {item.category_name}
                  <i className="fa-solid fa-caret-right float-end mt-1"></i>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-md-9">
            <div className="row">
              {filteredProducts(selectedCategory).map((item) => (
                <div className="col-md-4 mt-2" key={item.id}>
                  <div className="service-card card h-100">
                    <img
                      src={item.product_icon[0]}
                      alt={item.brand_name}
                      className="card-img-top w-100"
                      style={{ objectFit: "contain", width: "100%" }}
                    />
                    <div className="card-body" align={"left"}>
                      <h5 className="card-title fw-bold fs-6">
                        {truncateWords(item.main_category_name, wordLimit)}
                      </h5>
                      <p className="card-text fw-bold">
                        {truncateWords(item.brand_name, wordLimit)}
                      </p>
                      <p className="card-text">
                        <del className="text-danger fw-bold fs-5">
                          ₹{item.old_price}
                        </del>{" "}
                        <strong className="fw-bold fs-4">
                          ₹{item.new_price}
                        </strong>
                      </p>
                      <input
                        type="submit"
                        value="Buy Now"
                        className="w-50 m-0 p-0 p-2 my-btn fw-bold text-decoration-none"
                        onClick={() => goToNext(item.id)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile View with Collapsible Categories */}
      <div className="container-fluid d-block d-md-none my-3">
        <h5 className="text-start fw-bold text-muted my-3">
          Explore what's trending
        </h5>
        {categories.map((item) => (
          <div key={item.category_name}>
            <h6
              className="text-start fw-bold mt-3 my-btn p-3"
              onClick={() => toggleCollapse(item.category_name)}
              style={{ cursor: "pointer" }}
            >
              {item.category_name}
              <i
                className={`fa-solid fa-caret-right float-end mt-1 ${activeCollapse[item.category_name] ? "rotate" : ""
                  }`}
              ></i>
            </h6>
            <div className="scroll-container d-flex">
              {activeCollapse[item.category_name] &&
                filteredProducts(item.category_name).map((product) => (
                  <div
                    key={product.id}
                    className="shadow-sm w-50 rounded px-0 mx-3 product-card"
                    style={{ width: "100%" }}
                  >
                    <Link
                      to={`details/${product.id}`}
                      className="text-decoration-none"
                    >
                      <img
                        src={product.product_icon[0]}
                        alt={`Image of ${product.brand_name}`}
                        style={{
                          height: "120px",
                          width: "100%",
                          margin: "5px",
                          objectFit: "cover",
                        }}
                      />
                    </Link>
                    <div
                      className="text-center overflow-hidden px-3"
                      style={{ color: "#003C71" }}
                    >
                      <h6 className="card-title" align={"left"}>
                        <strong className="text-muted fw-bold">
                          {truncateWords(product.main_category_name, wordLimit)}
                        </strong>
                      </h6>
                      <p align={"left"} style={{ fontSize: "12px" }}>
                        {truncateWords(product.brand_name, wordLimit)}
                      </p>
                      <div className="my-3">
                        <input
                          type="submit"
                          value="Buy Now"
                          className="w-100 my-btn text-decoration-none"
                          onClick={() => goToNext(product.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>

      {/* Custom CSS */}
      <style>
        {`
          .scroll-container {
            overflow-x: auto;
            display: flex;
            gap: 10px;
          }
          .rotate {
            transform: rotate(90deg);
            transition: transform 0.3s ease;
          }
          .my-btn {
            transition: background-color 0.3s ease, color 0.3s ease;
          }
          .my-btn:hover {
            background: #003C71;
            color: white;
          }
          .product-card {
            transition: transform 0.3s ease;
          }
          .product-card:hover {
            transform: scale(1.05);
            box-shadow: rgba(0, 0, 0, 0.2) 0 10px 20px;
          }
        `}
      </style>
    </div>
  );
}

export default Index;
