import logo from "./logo.svg";
import "./App.css";
import { Provider } from "react-redux"; // Import Provider
import { store } from "./component/store/store"; // Adjust the path to your store.js
import { Header } from "./component/header/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer } from "./component/footer/Footer";
import Cart from "./component/Page/Cart";
import Login from "./component/Page/Login";
import SignUp from "./component/Page/SignUp";
import Service from "./component/Page/Service";
import Cards from "./component/Page/Cards";
import Index from "./component/Page/Index";
import About from "./component/Page/About";
import Contact from "./component/Page/Contact";
import Details from "./component/Page/Details";
import Gallery from "./component/Page/Gallery";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/details/:id" element={<Details />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/cards" element={<Cards />} />
          <Route path="/service" element={<Service />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
