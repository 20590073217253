import React from "react";
import "./About.css"; // Import the CSS file
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import service from "./service.png";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { Container } from "@mui/material";

function About() {
  window.scrollTo(500, 0);
  const imageData = [
    { id: 1, title: "Image 1", url: "assets/juli.png" },
    { id: 2, title: "Image 2", url: "assets/juli1.jpg" },
    { id: 3, title: "Image 3", url: "assets/juli2.jpg" },
    { id: 4, title: "Image 4", url: "assets/juli3.jpg" },
    { id: 5, title: "Image 5", url: "assets/juli4.jpg" },
    { id: 6, title: "Image 6", url: "assets/juli5.jpg" },
    { id: 7, title: "Image 4", url: "assets/juli6.jpg" },
    { id: 8, title: "Image 5", url: "assets/juli7.jpg" },
    { id: 9, title: "Image 6", url: "assets/juli8.jpg" },
  ];
  return (
    <div className="container py-5" style={{ fontFamily: "Arial, sans-serif" }}>
      {/* Header */}
      <div className="mb-4" align={"left"}>
        <h1 className="fw-bold" style={{ color: "#00356B" }}>
          Graphic Designer
        </h1>
        <h2 style={{ color: "#FF9800" }}>Services</h2>
      </div>

      {/* Service Details */}
      <div className="row align-items-center">
        {/* Left Column */}
        <div className="col-12 col-md-8 mb-4 mb-md-0">
          <div className="mb-3">
            <h4 className="fw-bold" align={"left"}>
              Our Services:
            </h4>
            <ul className="list-unstyled">
              <li className="mb-2 d-flex flex-wrap">
                <span className="d-flex align-items-center me-4 mb-2">
                  <FaCheckCircle
                    className="me-2"
                    style={{ color: "#FF9800" }}
                  />
                  <strong>Logo Design</strong>
                </span>
                <span className="d-flex align-items-center mb-2">
                  <FaCheckCircle
                    className="me-2"
                    style={{ color: "#FF9800" }}
                  />
                  <strong>Digital Design</strong>
                </span>
              </li>
              <li className="mb-2 d-flex flex-wrap">
                <span className="d-flex align-items-center me-4 mb-2">
                  <FaCheckCircle
                    className="me-2"
                    style={{ color: "#FF9800" }}
                  />
                  <strong>Print Design</strong>
                </span>
                <span className="d-flex align-items-center mb-2">
                  <FaCheckCircle
                    className="me-2"
                    style={{ color: "#FF9800" }}
                  />
                  <strong>Brand Identity</strong>
                </span>
              </li>
            </ul>
          </div>
          <p className="text-muted" align={"left"}>
            Where creativity meets innovation, and designs come alive. Transform
            your vision into stunning visuals and digital experiences.
          </p>
          <p className="text-muted" align={"left"}>
            From wedding cards to business cards, flyers to logos, and websites
            to digital experiences, we craft designs that inspire and elevate
            your brand.
          </p>
        </div>

        {/* Right Column - Image */}
        <div className="col-12 col-md-4">
          <img
            src={service}
            alt="Graphic Design"
            className="img-fluid rounded-circle"
            style={{ border: "8px solid #FF9800", maxWidth: "100%" }}
          />
        </div>
      </div>

      {/* Contact Details */}
      <div className="mt-5" align={"left"}>
        <h5 className="fw-bold">JULI SRIVASTAV</h5>
        <p>ID No: 08EMQPK8633H1ZF</p>
        <p>
          <a
            href="https://www.techhubindia.co.in"
            target="_blank"
            rel="noreferrer"
            className="text-decoration-none"
          >
            www.techhubindia.co.in
          </a>
        </p>
        <div className="row">
          <div className="col-12 col-md-6 d-flex flex-wrap">
            <Link to="/contact" className="btn btn-warning me-3 mb-2">
              Contact Us
            </Link>
            <Link to="" className="btn btn-outline-primary me-3 mb-2">
              Call: 7014015639
            </Link>
            <button className="btn btn-success mb-2">
              Address: Mahalaxmipura, Baran Road, Kota, Rajasthan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
