import React from "react";

import {
  FaCode,
  FaMobileAlt,
  FaChartLine,
  FaShoppingCart,
} from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.css";
import "./Service.css"; // Make sure to include this CSS file
import { useNavigate } from "react-router-dom";

function Service() {
  window.scrollTo(500, 0);
  const _useNavigate=useNavigate()
  const gotopage=()=>{
    _useNavigate("/contact")
  }
  return (
    <div
      className="service-container container py-5"
      style={{ fontFamily: "-moz-initial" }}
    >
      <h2 className="text-center mb-4">Our Services</h2>
      <p className="text-center mb-5">
        We provide a wide range of services to meet your needs. Here’s what we
        offer:
      </p>
      <div className="row text-center">
        {/* Service 1 */}
        <div className="col-md-3 mb-4">
          <div className="service-card">
            <FaCode className="service-icon" />
            <h5 className="mt-3">Web Development</h5>
            <p className="text-muted">
              Build responsive and dynamic websites with the latest
              technologies.
            </p>
            <br />
            <input type="submit" value="Get In Touch" className="btn btn-info" onClick={gotopage}/>
          </div>
        </div>

        {/* Service 2 */}
        <div className="col-md-3 mb-4">
          <div className="service-card">
            <FaMobileAlt className="service-icon" />
            <h5 className="mt-3">Mobile App Development</h5>
            <p className="text-muted">
              Create engaging and user-friendly mobile applications for iOS and
              Android.
            </p>
            <br />
            <input type="submit" value="Get In Touch" className="btn btn-info" onClick={gotopage}/>
          </div>
        </div>

        {/* Service 3 */}
        <div className="col-md-3 mb-4">
          <div className="service-card">
            <FaChartLine className="service-icon" />
            <h5 className="mt-3">College based projects</h5>
            <p className="text-muted">
              Transform data into actionable insights for better
              decision-making.
            </p>
            <br />
            <input type="submit" value="Get In Touch" className="btn btn-info" onClick={gotopage}/>
          </div>
        </div>

        {/* Service 4 */}
        <div className="col-md-3 mb-4">
          <div className="service-card">
            <FaShoppingCart className="service-icon" />
            <h5 className="mt-3">E-commerce Solutions</h5>
            <p className="text-muted">
              Launch and manage your online store with our all-in-one solutions.
            </p>
            <br />
            <input type="submit" value="Get In Touch" className="btn btn-info" onClick={gotopage}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service;
